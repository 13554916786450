
import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EventMessage {
 
  observable : Observable<any>;
  observer: Observer<any>;
 
   constructor( ) {
    //this.observable = new Observable(this.observer : Observer);
    this.observable = Observable.create((observer : Observer<any>) => {
      this.observer = observer;
    }).pipe(share());
  }

  subscribe(eventName : any , callback:any){
      
    const subscriber : Subscription = this.observable
          .pipe (
              filter(event => {               
                return event.name  === eventName;
              })
          ).subscribe(callback);
    return subscriber;
  }


  toggleRouteModal( node: string, action: string){
    this.broadcast({name : 'toggleRouteModal',node: node , action: action});   
  }

  info(data: any){
    this.broadcast({name : 'info', data: JSON.stringify(data)});   
  }

  confirm(message: string, component: any){
    this.broadcast({name : 'confirm', message: message, component:component});   
  }

  error(data: any){   
    this.broadcast({name : 'error', data: JSON.stringify(data)});   
  }

  broadcast(event: any){   
      if(this.observer != null){
        this.observer.next(event);
    }
  }

  destroy(subscriber : Subscription){
    subscriber.unsubscribe();
  }


}


