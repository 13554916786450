import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { User } from '../model/user';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getItemString(key: string) {
    return localStorage.getItem(key);
  }
  stringifyItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  parseItem<T>(key: string) {
    return JSON.parse(localStorage.getItem(key)) as T;
  }

  setItem(key: string, item: any) {
    localStorage.setItem(key, item);
  }

  getItemJSON(key: string) {
    if(localStorage.getItem(key)){
      return JSON.parse(localStorage.getItem(key));
    }else{
      return null;
    }    
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }


  clean(): void {
    window.sessionStorage.clear();
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): User {
    var decoded = jwt_decode(this.getToken());  
    let user = Object.assign(new User(), decoded);
    return user;
  }

  public getToken(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    var token = null;
    if (user) {
      return JSON.parse(user).access_token;
    }
    return '';
  }



  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }

}

