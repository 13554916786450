import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SectionsModule } from './sections/sections.module';
import { HeaderComponent } from './shared/header/header.component';
import { EventMessageComponent } from './shared/messages/event-message.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RequestInterceptor } from './core/interceptors/request-interceptor';
import { RouteModalComponent } from './home/route-modal/route-modal.component';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';


export function myMonacoLoad() {
  (window as any).monaco.languages.register({ id: 'microservicios' });


  (window as any).monaco.languages.setMonarchTokensProvider('microservicios', {
    tokenizer: {
      root: [
        [/\[\bmodule\b/, 'keyword.module'],
        [/\[\bimport\b/, 'keyword.import'],
        [/\[\bextends\b/, 'keyword.extends'],
        [/\[\btemplate\b/, 'keyword.template'],
        [/\[\bquery\b/, 'keyword.query'],
        [/\[\bpublic\b/, 'keyword.public'],
        [/\[\bprivate\b/, 'keyword.private'],
        [/\[\bprotected\b/, 'keyword.protected'],
        [/\[\bguard\b/, 'keyword.guard'],
        [/\[\binit\b/, 'keyword.init'],
        [/\[\boverrides\b/, 'keyword.overrides'],
        [/\[\beach\b/, 'keyword.each'],
        [/\[\bbefore\b/, 'keyword.before'],
        [/\[\bafter\b/, 'keyword.after'],
        [/\[\bfor\b/, 'keyword.for'],
        [/\[\bif\b/, 'keyword.if'],
        [/\[\belseif\b/, 'keyword.elseif'],
        [/\[\belse\b/, 'keyword.else'],
        [/\[\blet\b/, 'keyword.let'],
        [/\[\belselet\b/, 'keyword.elselet'],
        [/\[\btrace\b/, 'keyword.trace'],
        [/\[\bmacro\b/, 'keyword.macro'],
        [/\[\bfile\b/, 'keyword.file'],
        [/\[\bmode\b/, 'keyword.mode'],
        [/\[\btext_explicit\b/, 'keyword.text_explicit'],
        [/\[\bcode_explicit\b/, 'keyword.code_explicit'],
        [/\[\bsuper\b/, 'keyword.super'],
        [/\[\bstdout\b/, 'keyword.stdout'],
        [/\[\bcomment\b/, 'keyword.comment'],
       

           // Brackets
           [ /\[/, 'delimiter.square.open' ],
           [ /.*\/\]/, 'delimiter.square.close' ],
   
           // Numbers
           [ /\b\d+\b/, 'number' ],
   
           // Strings
           [ /".*?"/, 'string.double' ],
           [ /'.*?'/, 'string.single' ],
   
           // Comments
           [ /\/\/.*/, 'comment.line' ],
           [ /\/\*/, 'comment.block', '@comment' ],
      ],
      comment: [
        [ /\[comment.*\/\]/, 'comment.block', '@pop' ],
        [ /.*/, 'comment.block' ]
      ],
  
      brackets: [
        [ /\[/, 'delimiter.square.open', '@brackets' ],
        [ /.*\/\]/, 'delimiter.square.close', '@pop' ],
        { include: 'root' },
      ],
    },
  });

  (window as any).monaco.languages.registerCompletionItemProvider('microservicios', {
    provideCompletionItems: () => {
      const acceleoKeywords = [
        'module', 'import', 'extends', 'template', 'query', 'public',
        'private', 'protected', 'guard', 'init', 'overrides', 'each',
        'before', 'after', 'for', 'if', 'elseif', 'else', 'let', 'elselet',
        'trace', 'macro', 'file', 'mode', 'text_explicit', 'code_explicit',
        'super', 'stdout'
      ];

      const oclKeywords = [
        'and', 'body', 'context', 'def', 'derive', 'else', 'end', 'if',
        'endpackage', 'false', 'if', 'implies', 'in', 'init', 'inv',
        'invalid', 'let', 'not', 'null', 'or', 'package', 'post', 'pre',
        'self', 'static', 'then', 'true', 'xor'
      ];

      const acceleoItems = acceleoKeywords.map(keyword => ({
        label: keyword,
        kind: (window as any).monaco.languages.CompletionItemKind.Keyword,
      }));

      const oclItems = oclKeywords.map(keyword => ({
        label: keyword,
        kind: (window as any).monaco.languages.CompletionItemKind.Keyword,
      }));

      return [...acceleoItems, ...oclItems];
    },
  });
}


const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: myMonacoLoad
};

console.log(monacoConfig);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    EventMessageComponent,
    LoginComponent,
    RouteModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SectionsModule,
    CommonModule,
    MonacoEditorModule.forRoot(monacoConfig) ,
    FormsModule,
    RouterModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


