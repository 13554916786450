<header>
    <nav class="flex items-center justify-between p-6 container mx-auto">
        <!-- Logo -->
        <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-indigo-700" fill="none"  stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
              </svg>
            <span class="no-underline hover:no-underline font-bold text-2xl lg:text-4xl">mic-generator-playground-api</span>
        </div>
        <!-- Menu items -->
        <div class="text-lg text-gray-600 hidden lg:flex">
            <a href="#" class="block mt-4 lg:inline-block text-indigo-600 lg:mt-0 mr-10">
                Home
            </a>
            <a href="#/sections" class="block mt-4 lg:inline-block hover:text-gray-700 lg:mt-0 mr-10">
                Secciones
            </a>
        </div>

        
    </nav>
    <div #mobMenu class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                Home
            </a>
        </div>
    </div>

</header>
