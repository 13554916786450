import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { SectionsComponent } from './sections/sections.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/guard/auth-guard';

const routes: Routes =[
    { path: '',             component: HomeComponent ,canActivate:[AuthGuard]},
    { path: 'process/:processId',             component: HomeComponent },
    { path: 'sections',          component: SectionsComponent    },   
    {
      path: 'login',
      component: LoginComponent,
      pathMatch: 'full'
    },
    { path: '', redirectTo: '/login', pathMatch: 'full'},
    { path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [],
})
export class AppRoutingModule { }


