<div class="flex h-screen">

  <!-- Sidebar -->
  <div class="rounded-r bg-gray-900 xl:hidden flex justify-between w-full p-6 items-center ">
    <!--- more free and premium Tailwind CSS components at https://tailwinduikit.com/ --->

  </div>
  <div id="Main"
    class="xl:rounded-r transform  xl:translate-x-0  ease-in-out transition duration-500 flex justify-start items-start h-full  w-full sm:w-64 bg-gray-900 flex-col">
    <!--- more free and premium Tailwind CSS components at https://tailwinduikit.com/ --->

    <div class="hidden xl:flex justify-start p-6 items-center space-x-3">

      <p class="text-2xl leading-6 text-white">Playground</p>
    </div>
    <div class="mt-6 flex flex-col justify-start items-center  pl-4 w-full border-gray-600 border-b space-y-3 pb-5 ">
      <button (click)="run()"
        class="flex jusitfy-start items-center space-x-6 w-full  focus:outline-none  focus:text-indigo-400  text-white rounded ">
        <svg class="fill-stroke " width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p class="text-base leading-4 ">Run</p>
      </button>

    </div>


  </div>


  <!-- Editor Area -->
  <div class="flex-1">


    <!-- File Tabs -->

    <div class="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap dark:border-gray-700">
      <button (click)="onCodeTab()"
        class="inline-flex items-center h-10 px-2 py-2 -mb-px text-center text-blue-600 border-b-2 border-blue-500 sm:px-4 -px-1 dark:border-blue-400 dark:text-blue-300 whitespace-nowrap focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-1 sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
        </svg>

        <span class="mx-1 text-sm sm:text-base">
          Code
        </span>
      </button>

      <button (click)="onModelTab()"
        class="inline-flex items-center h-10 px-2 py-2 -mb-px text-center text-gray-700  border-b-2 border-transparent sm:px-4 -px-1 dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-1 sm:w-6 sm:h-6" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
        </svg>

        <span class="mx-1 text-sm sm:text-base">
          Model
        </span>
      </button>


    </div>




    <div #codeEditor>

      <!-- Editor -->
      <ngx-monaco-editor class="editorContainer " [options]="editorOptions" [(ngModel)]="code">
      </ngx-monaco-editor>
    </div>


    <div #modelEditor class="hidden">

      <!-- Editor -->
      <ngx-monaco-editor class="editorContainer" [options]="editorModelOptions" [(ngModel)]="model">
      </ngx-monaco-editor>
    </div>


    <div class="console-log bg-gray-800 text-white p-4">
      <span class="text-green-400">$</span>
      <span class="text-blue-300"> console.log</span>
      <span class="text-white">('Hello, Tailwind CSS!');</span>

    </div>
    <div class="console-log bg-gray-800 text-white p-4">
      <span class="text-green-400">$</span>
      <span class="text-blue-300"> console.log</span>
      <span class="text-white">('Hello, Tailwind CSS!');</span>

    </div>
    <div class="console-log bg-gray-800 text-white p-4">
      <span class="text-green-400">$</span>
      <span class="text-blue-300"> console.log</span>
      <span class="text-white">('Hello, Tailwind CSS!');</span>

    </div>




  </div>


</div>